import React, { useState, useEffect } from 'react';
import FormResults from '../../components/FlightForm/FormResults';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  SelectorField,
  TimePickerField,
  DatePickerField,
  DurationPickerField,
  InputField,
  InputURLField,
  updateFields,
} from '../../components/FlightForm/FormFields/FormInputs';
import { Form, Spin, Row, Col, message, Radio, FormInstance } from 'antd';
import { fetchUserInputs } from '../../components/FlightForm/fetchFormData';
import { updateTrip } from '../../services/api';
import { useUserData } from '../../hooks/useUserData';
import { formatDuration, formatFlightTimeStamp } from '../../time-utils';
import { TripStatus } from '../../globalConstants';
import { createTrip } from '../../components/FlightHistory/FlightHistoryApiCalls';
import { useParams, useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes';
import { fetchAirports, fetchLocations } from '../../services/api';
import FormInbound from '../../components/FlightForm/FormInbound';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface Location {
  name: string;
  id: string;
  localTimeZone: string;
}

export interface FormValues {
  outFlightStartDate: Dayjs;
  outFlightStartTime: Dayjs;
  outDurationOfFlight: Dayjs;
  outAirportId: string;
  employmentLocationId: string;
  outLocationId: string;
  inDurationOfFlight: Dayjs;
}

interface TripData {
  email: string;
  id: string;
  status: TripStatus;
  timestamp: string;
  outFlightStartTimestamp: string;
  employmentLocationId: string;
  outDurationOfFlight: number;
  outAirportId: string;
  outLocationId: string;
}

const FlightForm: React.FC = () => {
  const [destinationTimezone, setDestinationTimezone] = useState<string | undefined>(undefined);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [isCalculationShown, setIsCalculationShown] = useState(false);
  const [isUpdatingTrip, setIsUpdatingTrip] = useState(false);
  const [airports, setAirports] = useState<Location[]>([]);
  const [employmentLocations, setEmploymentLocations] = useState<Location[]>([]);
  const [destinationLocations, setDestinationLocations] = useState<Location[]>([]);
  const [readOnly, setReadOnly] = useState(false);
  const [tripData, setTripData] = useState<TripData>({} as TripData);
  const [isReturnFlight, setIsReturnFlight] = useState(false);

  const [form] = Form.useForm();
  const userData = useUserData();
  const navigate = useNavigate();
  const urlParams = useParams();
  const UUID = urlParams.UUID;

  useEffect(() => {
    const fetchData = async () => {
      form.setFieldValue('userName', userData?.name);
      try {
        const [airports, employmentLocations, destinationLocations] = await Promise.all([
          fetchAirports({ type: 'HOME' }),
          fetchLocations({ type: 'EMPLOYMENT' }),
          fetchLocations({ type: 'DESTINATION' }),
        ]);
        setAirports(airports);
        setEmploymentLocations(employmentLocations);
        setDestinationLocations(destinationLocations);
      } catch {
        message.error('Got an error while fetching data, please try later!');
        navigate(ROUTES.history);
      }

      if (UUID) {
        await fetchAndUpdateUserInputs(UUID);
      }

      setIsLoadingUserData(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tripData.status == TripStatus.SUBMITTED && !readOnly) handleFormUpdate(UUID);
  }, [tripData.status]);

  const handleChange = async () => {
    const fieldNames = ['inLocationId', 'inAirportId'];
    updateFields(form, fieldNames);

    if (Object.values(form.getFieldsValue()).every((value) => value !== undefined && value != '')) {
      setIsCalculationShown(false);
      await form
        .validateFields()
        .then(async (values) => {
          if (values) {
            setIsUpdatingTrip(true);
            if (!readOnly) await handleFormUpdate(UUID);
            setIsCalculationShown(true);
          }
        })
        .catch((err) => message.error(err));
    }
  };

  async function fetchAndUpdateUserInputs(UUID: string) {
    const userInputs = await fetchUserInputs(UUID);
    userInputs.inFlightStartTimestamp == 'null' ? setIsReturnFlight(false) : setIsReturnFlight(true);
    form.setFieldsValue({
      outFlightStartDate: dayjs(userInputs.outFlightStartTimestamp),
      outFlightStartTime: dayjs(userInputs.outFlightStartTimestamp),
      employmentLocationId: userInputs.employmentLocationId,
      outDurationOfFlight: dayjs(dayjs.duration({ minutes: userInputs.outDurationOfFlight }).asMilliseconds()).utc(),
      outAirportId: userInputs.outAirportId,
      outLocationId: userInputs.outLocationId,
      tripDetailsUrl: userInputs.tripDetailsUrl,
    });

    if (userInputs.inDurationOfFlight) {
      setIsReturnFlight(true);
      const selectedDestination = destinationLocations.find((item) => item.id === userInputs.inLocationId);
      form.setFieldsValue({
        inFlightStartDate: dayjs(userInputs.inFlightStartTimestamp),
        inFlightStartTime: dayjs(userInputs.inFlightStartTimestamp).tz(selectedDestination?.localTimeZone),
        inDurationOfFlight: dayjs(dayjs.duration({ minutes: userInputs.inDurationOfFlight }).asMilliseconds()).utc(),
        inAirportId: userInputs.inAirportId,
        inLocationId: userInputs.inLocationId,
      });
    }
    if (userInputs.status == TripStatus.SUBMITTED) {
      setReadOnly(true);
    }
    setTripData(userInputs);
    setIsCalculationShown(true);
  }

  const handleFormUpdate = async (UUID?: string) => {
    try {
      let data;
      const values = form.getFieldsValue();

      const formatedOutFlightTimeStamp = formatFlightTimeStamp(values.outFlightStartDate, values.outFlightStartTime);
      const formatedOutDuration = formatDuration(values.outDurationOfFlight);
      if (!UUID) {
        data = await createTrip();
        message.success('Trip saved successfully!');
      } else {
        data = { ...tripData };
      }

      const updateTripDto = {
        outFlightStartTimestamp: formatedOutFlightTimeStamp,
        outDurationOfFlight: formatedOutDuration,
        outAirportId: values['outAirportId'],
        employmentLocationId: values['employmentLocationId'],
        outLocationId: values['outLocationId'],
        tripDetailsUrl: values['tripDetailsUrl'],
        id: data.id,
        status: UUID ? data.status : TripStatus.DRAFT,
        email: data.email,
        timestamp: data.timestamp,
      };
      if (isReturnFlight) {
        const selectedDestination = destinationLocations.find((item) => item.id === values['inLocationId']);
        setDestinationTimezone(selectedDestination?.localTimeZone);

        const formatedInFlightTimeStamp = formatFlightTimeStamp(
          values.inFlightStartDate,
          values.inFlightStartTime,
          selectedDestination?.localTimeZone
        );
        const formatedInDuration = formatDuration(values.inDurationOfFlight);

        const updateReturnTripDto = {
          ...updateTripDto,
          inFlightStartTimestamp: formatedInFlightTimeStamp,
          inDurationOfFlight: formatedInDuration,
          inAirportId: values['inAirportId'],
          inLocationId: values['inLocationId'],
        };
        await updateTrip(updateReturnTripDto, data.id);
      } else {
        await updateTrip(updateTripDto, data.id);
      }

      if (!UUID) navigate(ROUTES.form + `/${data.id}`);
      setTripData(data);

      if (data.status == TripStatus.SUBMITTED) {
        message.success('Successfully submitted');
        navigate(ROUTES.history);
      }
    } catch (error) {
      message.error('Something went wrong');
    } finally {
      setIsUpdatingTrip(false);
    }
  };

  const getInboundFormValues = (form: FormInstance) => ({
    inFlightStartDate: form.getFieldValue('inFlightStartDate'),
    inFlightStartTime: form.getFieldValue('inFlightStartTime'),
    inDurationOfFlight: form.getFieldValue('inDurationOfFlight'),
    inAirportId: form.getFieldValue('inAirportId'),
    inLocationId: form.getFieldValue('inLocationId'),
  });

  const submitForm = async () => {
    setTripData((tripData) => ({
      ...tripData,
      status: TripStatus.SUBMITTED,
    }));
  };

  return (
    <div className='frame'>
      <h2 className='frame-text'>Business trip</h2>
      <div className='frame-box'>
        <Form form={form} layout='vertical'>
          <label className='form-type-label '>TRAVEL TYPE:</label>

          <Radio.Group
            disabled={readOnly || isLoadingUserData}
            value={isReturnFlight ? 'return' : 'single'}
            onChange={(e) => setIsReturnFlight(e.target.value === 'return')}
            className='padding-y-m'
          >
            <Radio value='single'>Single</Radio>
            <Radio value='return'>Return</Radio>
          </Radio.Group>
          <Row gutter={16}>
            <Col>
              <InputField name='userName' label='Name Surname' testId='userNameInput' loading={isLoadingUserData} />
            </Col>
            <Col>
              <SelectorField
                disabled={readOnly}
                name='employmentLocationId'
                label='Employment location'
                handleChange={handleChange}
                selectorData={employmentLocations}
                loading={isLoadingUserData}
              />
            </Col>
          </Row>
          <label className='form-type-label '>OUTBOUND</label>
          <Row gutter={16}>
            <Col>
              <DatePickerField
                disabled={readOnly}
                name='outFlightStartDate'
                label='Flight start date'
                handleChange={handleChange}
                testId='outFlightStartDate'
                loading={isLoadingUserData}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <SelectorField
                disabled={readOnly}
                name='outAirportId'
                label='Flight start location'
                handleChange={handleChange}
                selectorData={airports}
                loading={isLoadingUserData}
              />
            </Col>
            <Col>
              <TimePickerField
                disabled={readOnly}
                name='outFlightStartTime'
                label='Flight start time'
                testId='outFlightStartTime'
                handleChange={handleChange}
                loading={isLoadingUserData}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <SelectorField
                disabled={readOnly}
                name='outLocationId'
                label='Final destination'
                handleChange={handleChange}
                selectorData={destinationLocations}
                loading={isLoadingUserData}
              />
            </Col>
            <Col>
              <DurationPickerField
                disabled={readOnly}
                name='outDurationOfFlight'
                label='Flight duration'
                testId='outDurationOfFlight'
                handleChange={handleChange}
                loading={isLoadingUserData}
              />
            </Col>
          </Row>
          {isReturnFlight ? (
            <FormInbound
              disabled={readOnly}
              handleChange={handleChange}
              airports={airports}
              destinationLocations={destinationLocations}
              loading={isLoadingUserData}
            />
          ) : null}
          <InputURLField
            name='tripDetailsUrl'
            testId='tripDetailsUrl'
            loading={isLoadingUserData}
            disabled={readOnly}
            handleChange={handleChange}
          />
        </Form>
        <Spin spinning={isUpdatingTrip} tip='Calculating please wait' />
        {isCalculationShown && (
          <FormResults
            isFormSubmitted={submitForm}
            disabled={readOnly}
            formValues={{
              ...form.getFieldsValue(),
              ...(isReturnFlight && getInboundFormValues(form)),
            }}
            UUID={tripData.id}
            isReturnFlight={isReturnFlight}
            destinationTimezone={destinationTimezone}
          />
        )}
      </div>
    </div>
  );
};

export default FlightForm;
